import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import BallotIcon from "@mui/icons-material/Ballot";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import WorkIcon from "@mui/icons-material/Work";
import BadgeIcon from "@mui/icons-material/Badge";
import { personsinfoTableName } from "../../config";

export const searchDataInitalValues = {
  tableName: personsinfoTableName,
  fb_2015_profile_url: "",
  registration_score: null,
  importance_label: "",
  eligible_for_voting: "",
  unc_member_relation: "",
  turn_out_score: null,
  fathers_given_names: "",
  mothers_given_names: "",
  registered_for_election: "",
  party_support_score: null,
  appartment_number: "",
  swing_voter_label: "",
  phone_number: "",
  housing_status: "",
  email: "",
  total_childs: "",
  date_of_birth: "",
  year: "",
  month: "",
  day: "",
  full_name: "",
  full_name_at_birth: "",
  gender: "",
  age: "",
  age_group: "",
  marital_status: "",
  ethinicity: "",
  religion_group: "",
  street_name: "",
  community: "",
  polling_division_number: "",
  electoral_district: "",
  parliamentary_electoral_district: "",
  corporation: "",
  pq2relation: "",
  hholdsize: "",
  filterColumn: "",
  order: "",
  FilterStatments: "",
};
export const defaultColumns = [
  {
    name: "full_name",
    label: "Voter Name",
    visible: true,
  },
  {
    name: "full_name_at_birth",
    label: "Full Name At Birth",
    visible: true,
  },
  {
    name: "fathers_given_names",
    label: "Father's Given Name",
    visible: true,
  },
  {
    name: "mothers_given_names",
    label: "Mother's Given Name",
    visible: true,
  },
  {
    name: "date_of_birth",
    label: "Date Of Birth",
    visible: true,
  },
  {
    name: "age",
    label: "Age",
    visible: true,
  },
  {
    name: "age_group",
    label: "Age Range",
    visible: true,
  },
  {
    name: "gender",
    label: "Gender",
    visible: true,
  },
  {
    name: "marital_status",
    label: "Marital Status",
    visible: true,
  },
  {
    name: "total_childs",
    label: "Children",
    visible: true,
  },
  {
    name: "ethinicity",
    label: "Ethnic Group",
    visible: true,
  },
  {
    name: "religion_group",
    label: "Religion Group",
    visible: true,
  },
  { name: "street_name", label: "Street Name", visible: true },
  {
    name: "corporation",
    label: "Corporation",
    visible: true,
  },
  {
    name: "parliamentary_electoral_district",
    label: "Parliamentary Electoral District",
    visible: true,
  },

  { name: "appartment_number", label: "Apartment Number", visible: true },
  { name: "community", label: "Community", visible: true },
  {
    name: "electoral_district",
    label: "Municipal Electoral District",
    visible: true,
  },

  {
    name: "polling_division_number",
    label: "Polling Division",
    visible: true,
  },
  {
    name: "phone_number",
    label: "Phone Number",
    visible: true,
  },
  {
    name: "email",
    label: "Email",
    visible: true,
  },
  {
    name: "fb_2015_profile_url",
    label: "Facebook Profile Url",
    visible: true,
  },
  {
    name: "pq2relation",
    label: "Relationship To Head Of Household",
    visible: true,
  },
  {
    name: "hholdsize",
    label: "Number Of Persons In Household",
    visible: true,
  },
  {
    name: "housing_status",
    label: "Housing Status",
    visible: true,
  },
  {
    name: "registered_for_election",
    label: "Registered For Election",
    visible: true,
  },
  {
    name: "unc_member_relation",
    label: "UNC Member Relationship",
    visible: true,
  },
  {
    name: "eligible_for_voting",
    label: "Eligible For Voting",
    visible: true,
  },
  {
    name: "registration_score",
    label: "Registration Probability Score",
    visible: true,
  },
  {
    name: "turn_out_score",
    label: "Turnout Likelihood Score",
    visible: true,
  },
  {
    name: "party_support_score",
    label: "Party Support Rating",
    visible: true,
  },
  {
    name: "swing_voter_label",
    label: "Swing Voter Rating",
    visible: true,
  },
  {
    name: "importance_label",
    label: "Importance Score",
    visible: true,
  },
];

export const demoDefaultColumns = [
  {
    name: "full_name",
    label: "Voter Name",
    visible: true,
  },

  {
    name: "age",
    label: "Age",
    visible: true,
  },
  {
    name: "age_group",
    label: "Age Range",
    visible: true,
  },
  {
    name: "gender",
    label: "Gender",
    visible: true,
  },

  {
    name: "ethinicity",
    label: "Ethnic Group",
    visible: true,
  },
  {
    name: "religion_group",
    label: "Religion Group",
    visible: true,
  },
  { name: "street_name", label: "Street Name", visible: true },
  {
    name: "corporation",
    label: "Corporation",
    visible: true,
  },
  {
    name: "parliamentary_electoral_district",
    label: "Parliamentary Electoral District",
    visible: true,
  },

  { name: "appartment_number", label: "Apartment Number", visible: true },
  {
    name: "electoral_district",
    label: "Municipal Electoral District",
    visible: true,
  },

  {
    name: "polling_division_number",
    label: "Polling Division",
    visible: true,
  },
  {
    name: "phone_number",
    label: "Phone Number",
    visible: true,
  },

  {
    name: "registered_for_election",
    label: "Registered For Election",
    visible: true,
  },
  {
    name: "unc_member_relation",
    label: "UNC Member Relationship",
    visible: true,
  },
  {
    name: "eligible_for_voting",
    label: "Eligible For Voting",
    visible: true,
  },
  {
    name: "registration_score",
    label: "Registration Probability Score",
    visible: true,
  },
  {
    name: "turn_out_score",
    label: "Turnout Likelihood Score",
    visible: true,
  },
  {
    name: "party_support_score",
    label: "Party Support Rating",
    visible: true,
  },
  {
    name: "swing_voter_label",
    label: "Swing Voter Rating",
    visible: true,
  },
  {
    name: "importance_label",
    label: "Importance Score",
    visible: true,
  },
];
export const categories = {
  Name: [
    { value: "full_name", name: "Voter Name" },
    { value: "full_name_at_birth", name: "Full Name At Birth" },
    { value: "fathers_given_names", name: "Father's Given Name" },
    { value: "mothers_given_names", name: "Mother's Given Name" },
  ],
  Age: [
    { value: "date_of_birth", name: "Date Of Birth" },
    { value: "age", name: "Age" },
    { value: "age_group", name: "Age Range" },
  ],
  Demographics: [
    { value: "gender", name: "Gender" },
    { value: "ethinicity", name: "Ethnic Group" },
    { value: "religion_group", name: "Religion" },
    { value: "marital_status", name: "Marital Status" },
    { value: "total_childs", name: "Children Status" },
  ],
  Contact: [
    { value: "phone_number", name: "Phone Number" },
    { value: "email", name: "Email" },
    { value: "fb_2015_profile_url", name: "Facebook Profile URL" },
    { value: "profile_link", name: "Profile Url" },
    { value: "contact_url_company", name: "Company Url" },
  ],
  Address: [
    { value: "street_name", name: "Street Name" },

    { value: "community", name: "Community" },
    { value: "corporation", name: "Corporation" },
    {
      value: "parliamentary_electoral_district",
      name: "Parliamentary Electoral District",
    },
    { value: "electoral_district", name: "Municipal Electoral District" },

    { value: "polling_division_number", name: "Polling Division" },
  ],
  "Household Info": [
    {
      value: "pq2relation",
      name: "Relationship To Head Of Household",
    },
    {
      value: "hholdsize",
      name: "Number Of Persons In Household",
    },
    {
      value: "housing_status",
      name: "Housing Status",
    },
  ],
  "Election Info": [
    {
      value: "registration_score",
      name: "Registration Probability Score",
    },
    {
      value: "turn_out_score",
      name: "Turnout Likelihood Score",
    },
    {
      value: "party_support_score",
      name: "Party Support Rating",
    },
    {
      value: "swing_voter_label",
      name: "Swing Voter Rating",
    },
    {
      value: "importance_label",
      name: "Importance Score",
    },
    { value: "registered_for_election", name: "Registered For Election" },
    { value: "unc_member_relation", name: "UNC Member Relationship" },
    { value: "eligible_for_voting", name: "Eligible For Voting" },
  ],
  Professional: [
    {
      value: "profile_title",
      name: "Title",
    },
    {
      value: "profile_summary",
      name: "Summary",
    },
    {
      value: "skills",
      name: "Skills",
    },
    {
      value: "languages",
      name: "Languages",
    },
    {
      value: "honors",
      name: "Honors",
    },
    {
      value: "certifications",
      name: "Certifications",
    },
    {
      value: "publications",
      name: "Publications",
    },
    {
      value: "contact_url_portfolio",
      name: "Portfolio URL",
    },
    {
      value: "contact_url_blog",
      name: "Personal Blog URL",
    },
    {
      value: "contact_url_rss",
      name: "RSS Feed URL",
    },
    {
      value: "contact_url_other",
      name: "Other Contact Information URL",
    },
  ],
  "Career Info": [
    { value: "experience_json", name: "Experience" },
    { value: "education_json", name: "Education" },
  ],
};

export const demoCategories = {
  Name: [{ value: "full_name", name: "Voter Name" }],
  Age: [
    { value: "age", name: "Age" },
    { value: "age_group", name: "Age Range" },
  ],
  Demographics: [
    { value: "gender", name: "Gender" },
    { value: "ethinicity", name: "Ethnic Group" },
    { value: "religion_group", name: "Religion" },
  ],
  Contact: [{ value: "phone_number", name: "Phone Number" }],
  Address: [
    { value: "street_name", name: "Street Name" },

    { value: "corporation", name: "Corporation" },

    { value: "electoral_district", name: "Municipal Electoral District" },

    { value: "polling_division_number", name: "Polling Division" },
    { value: "appartment_number", name: "Appartment Number" },
  ],

  "Election Info": [
    {
      value: "registration_score",
      name: "Registration Probability Score",
    },
    {
      value: "turn_out_score",
      name: "Turnout Likelihood Score",
    },
    {
      value: "party_support_score",
      name: "Party Support Rating",
    },
    {
      value: "swing_voter_label",
      name: "Swing Voter Rating",
    },
    {
      value: "importance_label",
      name: "Importance Score",
    },
    { value: "registered_for_election", name: "Registered For Election" },
    { value: "unc_member_relation", name: "UNC Member Relationship" },
    { value: "eligible_for_voting", name: "Eligible For Voting" },
  ],
};
export const categoryIcons = {
  Name: <PersonIcon />,
  Age: <EventIcon />,
  Demographics: <SensorOccupiedIcon />,
  Contact: <ContactPhoneIcon />,
  Address: <LocationOnIcon />,
  "Household Info": <MapsHomeWorkIcon />,
  "Election Info": <BallotIcon />,
  Professional: <BadgeIcon />,
  "Career Info": <WorkIcon />,
};

export const CareerInfoFlagFields = [
  "profile_link",
  "contact_url_company",
  "profile_title",
  "profile_summary",
  "skills",
  "languages",
  "honors",
  "publications",
  "certifications",
  "contact_url_portfolio",
  "contact_url_blog",
  "contact_url_rss",
  "contact_url_other",
  "experience_json",
  "education_json",
];
export const fieldsToCheck = [
  "full_name",
  "profile_link",
  "contact_url_company",
  "profile_title",
  "profile_summary",
  "appartment_number",
  "skills",
  "languages",
  "honors",
  "publications",
  "certifications",
  "contact_url_portfolio",
  "contact_url_blog",
  "contact_url_rss",
  "contact_url_other",
  "experience_json",
  "education_json",
  "total_childs",
  "registration_score",
  "eligible_for_voting",
  "importance_label",
  "fb_2015_profile_url",
  "unc_member_relation",
  "fathers_given_names",
  "mothers_given_names",
  "turn_out_score",
  "registered_for_election",
  "party_support_score",
  "swing_voter_label",
  "email",
  "housing_status",
  "phone_number",
  "full_name_at_birth",
  "gender",
  "year",
  "month",
  "day",
  "marital_status",
  "ethinicity",
  "religion_group",
  "date_of_birth",
  "age",
  "age_group",
  "street_name",
  "comuunity",
  "polling_division_number",
  "corporation",
  "parliamentary_electoral_district",
  "electoral_district",
  "pq2relation",
  "hholdsize",
  "FilterStatments",
  "filterColumn",
  "order",
];

export const categoryMapping = {
  Name: "NAME",
  Address: "ADDRESS",
  Age: "AGE",
  Demographics: "DEMOGRAPHICS",
  Contact: "CONTACT",
  Household: "HOUSEHOLD",
  "Election Info": "VOTERS_HISTORY",
  Professional: "PROFESSIONAL_PROFILE",
  Experience: "WORK_EXPERIENCE",
  Education: "EDUCATION",
};
export const Profilecategories = {
  Name: [],
  Address: [],
  Age: [],
  Demographics: [],
  "Family Tree": [],
  Contact: [],
  Household: [],
  "Election Info": [],
  Professional: [],
  Experience: [],
  Education: [],
};
export const demoProfilecategories = {
  Name: [],
  Address: [],
  Age: [],
  Demographics: [],
  "Family Tree": [],
  Contact: [],
  Household: [],
  "Election Info": [],
};

export const columnMapping = {
  "Family Tree": [],
  Name: {
    full_name: "Voter Name",
    full_name_at_birth: "Full Name at Birth",
    fathers_given_names: "Father's Given Name",
    mothers_given_names: "Mother's Given Name",
    full_name_li: "Full Name",
  },
  Age: { date_of_birth: "Date of Birth", age: "Age", age_group: "Age Range" },

  Demographics: {
    gender: "Gender",
    marital_status: "Marital Status",
    ethinicity: "Ethinc Group",
    religion_group: "Religion Group",
  },

  Address: {
    street_name: "Street Name",
    community: "Community",
    polling_divison_number: "Polling Division",
    electoral_district: "Municipal Electoral District",
    parliamentary_electoral_district: "Parliamentary Electoral District",
    corporation: "Corporation",
    appartment_number: "Appartment Number",
  },

  Contact: {
    phone_number: "Phone Number",
    email: "Email",
    fb_2015_profile_url: "Facebook Profile Url",
    profile_link: "Profile Url",
    contact_url_company: "Company Url",
  },
  Household: {
    pq2relation: "Relationship To Head Of Household",
    hholdsize: "Number Of Persons In Household",
    rent_paid: "Housing Status",
  },
  "Election Info": {
    registration_score: "Registration Probability Score",
    turn_out_score: "Turnout Likelihood Score",
    party_support_score: "Partisan Support Rating",
    swing_voter_label: "Swing Voter Rating",
    importance_label: "Importance Score",
    registered_for_election: "Registered For Election",
    unc_member_relation: "UNC Member Relationship",
    eligible_for_voting: "Eligible For Voting",
  },
  Professional: {
    profile_title: "Title",
    profile_summary: "Summary",
    skills: "Skills",
    languages: "Languages",
    honors: "Honors",
    certifications: "Certifications",
    publications: "Publications",
    contact_url_portfolio: "Portfolio URL",
    contact_url_blog: "Personal Blog URL",
    contact_url_rss: "RSS Feed URL",
    contact_url_other: "Other Contact Information URL",
  },
  Experience: {
    experience_json: "Experience",
  },
  Education: {
    education_json: "Education",
  },
};
export const democolumnMapping = {
  "Family Tree": [],
  Name: {
    full_name: "Voter Name",
  },
  Age: { age: "Age", age_group: "Age Range" },

  Demographics: {
    gender: "Gender",
    ethinicity: "Ethinc Group",
    religion_group: "Religion Group",
  },

  Address: {
    street_name: "Street Name",
    polling_divison_number: "Polling Division",
    electoral_district: "Municipal Electoral District",
    parliamentary_electoral_district: "Parliamentary Electoral District",
    corporation: "Corporation",
    appartment_number: "Appartment Number",
  },

  Contact: {
    phone_number: "Phone Number",
  },
  Household: {
    pq2relation: "Relationship To Head Of Household",
    hholdsize: "Number Of Persons In Household",
    rent_paid: "Housing Status",
  },
  "Election Info": {
    registration_score: "Registration Probability Score",
    turn_out_score: "Turnout Likelihood Score",
    party_support_score: "Partisan Support Rating",
    swing_voter_label: "Swing Voter Rating",
    importance_label: "Importance Score",
    registered_for_election: "Registered For Election",
    unc_member_relation: "UNC Member Relationship",
    eligible_for_voting: "Eligible For Voting",
  },
  Professional: {
    profile_title: "Title",
    profile_summary: "Summary",
    skills: "Skills",
    languages: "Languages",
    honors: "Honors",
    certifications: "Certifications",
    publications: "Publications",
    contact_url_portfolio: "Portfolio URL",
    contact_url_blog: "Personal Blog URL",
    contact_url_rss: "RSS Feed URL",
    contact_url_other: "Other Contact Information URL",
  },
  Experience: {
    experience_json: "Experience",
  },
  Education: {
    education_json: "Education",
  },
};
export const fieldOptions = {
  corporation: [
    { label: "Select Municipality", value: "" },
    { label: "Borough Of Arima", value: "BOROUGH OF ARIMA" },
    { label: "Borough Of Chaguanas", value: "BOROUGH OF CHAGUANAS" },
    { label: "Borough Of Point Fortin", value: "BOROUGH OF POINT FORTIN" },
    { label: "City Of Port Of Spain", value: "CITY OF PORT OF SPAIN" },
    { label: "City Of San Fernando", value: "CITY OF SAN FERNANDO" },
    { label: "Couva/ Tabaquite/ Talparo", value: "COUVA/ TABAQUITE/ TALPARO" },
    { label: "Diego Martin", value: "DIEGO MARTIN" },
    { label: "Mayaro/ Rio Claro", value: "MAYARO/ RIO CLARO" },
    { label: "Penal/ Debe", value: "PENAL/ DEBE" },
    { label: "Princes Town", value: "PRINCES TOWN" },
    { label: "San Juan / Laventille", value: "SAN JUAN / LAVENTILLE" },
    { label: "Sangre Grande", value: "SANGRE GRANDE" },
    { label: "Siparia", value: "SIPARIA" },
    { label: "Tunapuna/ Piarco", value: "TUNAPUNA/ PIARCO" },
  ],

  profile_link: [
    { label: "Has Profile Link", value: "" },
    { label: "True", value: "PROFILE_LINK" },
    { label: "False", value: "FALSE" },
  ],
  contact_url_company: [
    { label: "Has Company Url", value: "" },
    { label: "True", value: "CONTACT_URL_COMPANY" },
    { label: "False", value: "FALSE" },
  ],
  profile_title: [
    { label: "Has Title", value: "" },
    { label: "True", value: "PROFILE_TITLE" },
    { label: "False", value: "FALSE" },
  ],
  profile_summary: [
    { label: "Has Profile Summary", value: "" },
    { label: "True", value: "PROFILE_SUMMARY" },
    { label: "False", value: "FALSE" },
  ],
  skills: [
    { label: "Has Skills", value: "" },
    { label: "True", value: "SKILLS" },
    { label: "False", value: "FALSE" },
  ],
  languages: [
    { label: "Has Languages", value: "" },
    { label: "True", value: "LANGUAGES" },
    { label: "False", value: "FALSE" },
  ],
  honors: [
    { label: "Has Honors ", value: "" },
    { label: "True", value: "HONORS" },
    { label: "False", value: "FALSE" },
  ],
  publications: [
    { label: "Has Publications", value: "" },
    { label: "True", value: "PUBLICATIONS" },
    { label: "False", value: "FALSE" },
  ],
  certifications: [
    { label: "Has Certifications", value: "" },
    { label: "True", value: "CERTIFICATIONS" },
    { label: "False", value: "FALSE" },
  ],
  contact_url_portfolio: [
    { label: "Has Portfolio URL", value: "" },
    { label: "True", value: "CONTACT_URL_PORTFOLIO" },
    { label: "False", value: "FALSE" },
  ],
  contact_url_blog: [
    { label: "Has Personal Blog URL", value: "" },
    { label: "True", value: "CONTACT_URL_BLOG" },
    { label: "False", value: "FALSE" },
  ],
  contact_url_rss: [
    { label: "Has RSS Feed URL", value: "" },
    { label: "True", value: "CONTACT_URL_RSS" },
    { label: "False", value: "FALSE" },
  ],
  contact_url_other: [
    { label: "Has Other Contact URL", value: "" },
    { label: "True", value: "CONTACT_URL_OTHER" },
    { label: "False", value: "FALSE" },
  ],
  experience_json: [
    { label: "Has Experience Info", value: "" },
    { label: "True", value: "EXPERIENCE_JSON" },
    { label: "False", value: "FALSE" },
  ],
  education_json: [
    { label: "Has Education Info", value: "" },
    { label: "True", value: "EDUCATION_JSON" },
    { label: "False", value: "FALSE" },
  ],
  total_childs: [
    { label: "Select Children Status", value: "" },
    { label: "1 Child", value: "1  CHILD" },
    { label: "2 Children", value: "2  CHILDREN" },
    { label: "3 Children", value: "3  CHILDREN" },
    { label: "4 Children", value: "4  CHILDREN" },
    { label: "5 Children", value: "5  CHILDREN" },
    { label: "6 Children", value: "6  CHILDREN" },
    { label: "7 Children", value: "7  CHILDREN" },
    { label: "8 Children", value: "8  CHILDREN" },
    { label: "9 Children", value: "9  CHILDREN" },
    { label: "10 Children", value: "10  CHILDREN" },
    { label: "11 Children", value: "11  CHILDREN" },
    { label: "12 Children", value: "12  CHILDREN" },
    { label: "13 Children", value: "13  CHILDREN" },
    { label: "14 Children", value: "14  CHILDREN" },
    { label: "15 Children", value: "15  CHILDREN" },
    { label: "16 Children", value: "16  CHILDREN" },
    { label: "17 Children", value: "17  CHILDREN" },
    { label: "18 Children", value: "18  CHILDREN" },
    { label: "19 Children", value: "19  CHILDREN" },
    { label: "20 Children", value: "20  CHILDREN" },
    { label: "21 Children", value: "21  CHILDREN" },
    { label: "22 Children", value: "22  CHILDREN" },
    { label: "No Children", value: "NONE" },
  ],

  age_group: [
    { label: "Select Age Range", value: "" },
    { label: "Ineligible Voters", value: "0-18 (INELIGIBLE VOTERS)" },
    {
      label: "Youth/First-Time Voters",
      value: "18-24 (YOUTH/FIRST-TIME VOTERS)",
    },
    { label: "Young Professionals", value: "25-34 (YOUNG PROFESSIONALS)" },
    { label: "Young Families", value: "35-44 (YOUNG FAMILIES)" },
    {
      label: "Parents Of Teens/Adults",
      value: "45-64 (PARENTS OF TEENS/ADULTS)",
    },
    { label: "Retirees/Seniors", value: "65+ (RETIREES/SENIORS)" },
  ],

  eligible_for_voting: [
    { label: "Select Eligiblity", value: "" },
    { label: "Eligible", value: "ELIGIBLE" },
    { label: "Ineligible", value: "INELIGIBLE" },
    { label: "Not Stated", value: "NOT STATED" },
  ],

  unc_member_relation: [
    { label: "Select Relation", value: "" },
    { label: "UNC Member", value: "UNC MEMBER" },
    {
      label: "Family Memeber Of UNC Memebers",
      value: "FAMILY MEMBERS OF UNC MEMBERS",
    },
    {
      label: "Same Household As UNC Members",
      value: "SAME HOUSEHOLD AS UNC MEMBERS",
    },
    { label: "BLank", value: "NaN" },
  ],

  swing_voter_label: [
    { label: "Select Score", value: "" },
    { label: "Low Swing", value: "LOW SWING" },
    { label: "Intermediate Swing", value: "INTERMEDIATE SWING" },
    { label: "High Swing", value: "HIGH SWING" },
  ],

  importance_label: [
    { label: "Select Score", value: "" },
    { label: "Low Importance", value: "LOW IMPORTANCE" },
    { label: "Intermediate Importance", value: "INTERMEDIATE IMPORTANCE" },
    { label: "High Importance", value: "HIGH IMPORTANCE" },
  ],
  registered_for_election: [
    { label: "Select Status", value: "" },
    { label: "Registered", value: "REGISTERED" },
    { label: "Unregistered", value: "UNREGISTERED" },
  ],

  parliamentary_electoral_district: [
    { label: "Select Parliamentary Electoral District", value: "" },
    { label: "Arima", value: "ARIMA" },
    { label: "Arouca/Maloney", value: "AROUCA/MALONEY" },
    { label: "Barataria/San Juan", value: "BARATARIA /SAN JUAN" },
    { label: "Caroni Central", value: "CARONI CENTRAL" },
    { label: "Caroni East", value: "CARONI EAST" },
    { label: "Chaguanas East", value: "CHAGUANAS EAST" },
    { label: "Chaguanas West", value: "CHAGUANAS WEST" },
    { label: "Couva North", value: "COUVA NORTH" },
    { label: "Couva South", value: "COUVA SOUTH" },
    { label: "Cumuto/Manzanilla", value: "CUMUTO/MANZANILLA" },
    { label: "D'Abadie/O'Meara", value: "D'ABADIE/O'MEARA" },
    { label: "Diego Martin Central", value: "DIEGO MARTIN CENTRAL" },
    { label: "Diego Martin North/East", value: "DIEGO MARTIN NORTH/EAST" },
    { label: "Diego Martin West", value: "DIEGO MARTIN WEST" },
    { label: "Fyzabad", value: "FYZABAD" },
    { label: "La Brea", value: "LA BREA" },
    { label: "La Horquetta/Talparo", value: "LA HORQUETTA/TALPARO" },
    { label: "Laventille East/Morvant", value: "LAVENTILLE EAST/MORVANT" },
    { label: "Laventille West", value: "LAVENTILLE WEST" },
    { label: "Lopinot/Bon Air West", value: "LOPINOT/BON AIR WEST" },
    { label: "Mayaro", value: "MAYARO" },
    { label: "Moruga/Tableland", value: "MORUGA/TABLELAND" },
    { label: "NAPARIMA", value: "NAPARIMA" },
    { label: "Oropouche East", value: "OROPOUCHE EAST" },
    { label: "Oropouche West", value: "OROPOUCHE WEST" },
    { label: "Pointe-a-Pierre", value: "POINTE-A-PIERRE" },
    { label: "Point Fortin", value: "POINT FORTIN" },
    {
      label: "Port-of-Spain North/St. Ann's West",
      value: "PORT-OF-SPAIN NORTH/ST. ANN'S WEST",
    },
    { label: "Port-of-Spain South", value: "PORT-OF-SPAIN SOUTH" },
    { label: "Princes Town", value: "PRINCES TOWN" },
    { label: "San Fernando East", value: "SAN FERNANDO EAST" },
    { label: "San Fernando West", value: "SAN FERNANDO WEST" },
    { label: "Siparia", value: "SIPARIA" },
    { label: "St. Ann's East", value: "ST. ANN'S EAST" },
    { label: "St. Augustine", value: "ST. AUGUSTINE" },
    { label: "St. Joseph", value: "ST. JOSEPH" },
    { label: "Tabaquite", value: "TABAQUITE" },
    { label: "Tobago East", value: "TOBAGO EAST" },
    { label: "Tobago West", value: "TOBAGO WEST" },
    { label: "Toco/Sangre Grande", value: "TOCO/SANGRE GRANDE" },
    { label: "Tunapuna", value: "TUNAPUNA" },
  ],
  gender: [
    { label: "Select Gender", value: "" },
    { label: "Female", value: "FEMALE" },
    { label: "Male", value: "MALE" },
    { label: "Other", value: "NaN" },
  ],
  housing_status: [
    { label: "Select Status", value: "" },
    { label: "Owner", value: "OWNER" },
    { label: "Renter", value: "RENTER" },
    { label: "Not Stated", value: "NOT STATED" },
  ],
  ethinicity: [
    { label: "Select Ethnic Group", value: "" },
    { label: "African", value: "AFRICAN" },
    { label: "Caucasian", value: "CAUCASIAN" },
    { label: "Chinese", value: "CHINESE" },
    { label: "East Indian", value: "EAST INDIAN" },
    { label: "Indigenous", value: "INDIGENOUS" },
    {
      label: "Mixed - African/ East Indian",
      value: "MIXED - AFRICAN/ EAST INDIAN",
    },
    { label: "Mixed - Other", value: "MIXED - OTHER" },
    { label: "Portuguese", value: "PORTUGUESE" },
    { label: "Syrian/ Lebanese", value: "SYRIAN/ LEBANESE" },
    { label: "Other Ethnic Group", value: "OTHER ETHNIC GROUP" },
    { label: "Not Stated", value: "NOT STATED" },
  ],
  religion_group: [
    { label: "Select Religion", value: "" },
    { label: "Anglican", value: "ANGLICAN" },
    { label: "Baptist - Other", value: "BAPTIST - OTHER" },
    {
      label: "Baptist - Spiritual Shouter Baptist",
      value: "BAPTIST - SPIRITUAL SHOUTER BAPTIST",
    },
    { label: "Hinduism", value: "HINDUISM" },
    { label: "Islam", value: "ISLAM" },
    { label: "Jehovah's Witness", value: "JEHOVAH'S WITNESS" },
    { label: "Methodist", value: "METHODIST" },
    { label: "Moravian", value: "MORAVIAN" },
    { label: "Orisha", value: "ORISHA" },
    {
      label: "Pentecostal/Evangelical/Full Gospel",
      value: "PENTECOSTAL/EVANGELICAL/FULL GOSPEL",
    },
    {
      label: "Presbyterian/Congregational",
      value: "PRESBYTERIAN/CONGREGATIONAL",
    },
    { label: "Rastafarian", value: "RASTAFARIAN" },
    { label: "Roman Catholic", value: "ROMAN CATHOLIC" },
    { label: "Seven Day Adventist", value: "SEVEN DAY ADVENTIST" },
  ],
  marital_status: [
    { label: "Select Marital Status", value: "" },
    { label: "Divorced", value: "DIVORCED" },
    { label: "Legally Separated", value: "LEGALLY SEPARATED" },
    { label: "Married", value: "MARRIED" },
    { label: "Never Married", value: "NEVER MARRIED" },
    { label: "Not Stated", value: "NOT STATED" },
    { label: "Widowed", value: "WIDOWED" },
    { label: "Blank", value: "BLANK" },
  ],
  pq2relation: [
    { label: "Select Relation", value: "" },
    {
      label: "Adopted Child Of Head & Spouse/Oartner",
      value: "ADOPTED CHILD OF HEAD & SPOUSE/PARTNER",
    },
    { label: "Child Of Head Only", value: "CHILD OF HEAD ONLY" },
    {
      label: "Child Of Head and Spouse/Partner",
      value: "CHILD OF HEAD AND SPOUSE/PARTNER",
    },
    {
      label: "Child Of Spouse/Partner Only",
      value: "CHILD OF SPOUSE/PARTNER ONLY",
    },
    { label: "Domestic Employee", value: "DOMESTIC EMPLOYEE" },
    {
      label: "Grandchild Of Head/Spouse/Partner",
      value: "GRANDCHILD OF HEAD/SPOUSE/PARTNER",
    },
    { label: "Head", value: "HEAD" },
    { label: "Other Non Relative", value: "OTHER NON RELATIVE" },
    {
      label: "Other Relative Of Head/Spouse/Partner",
      value: "OTHER RELATIVE OF HEAD/SPOUSE/PARTNER",
    },
    {
      label: "Parent Of Head/Spouse/Partner",
      value: "PARENT OF HEAD/SPOUSE/PARTNER",
    },
    { label: "Partner Of Head", value: "PARTNER OF HEAD" },
    { label: "Spouse Of Head", value: "SPOUSE OF HEAD" },
    { label: "Spouse/Partner Of Child", value: "SPOUSE/PARTNER OF CHILD" },
  ],
};

export const selectFieldValues = {
  parliamentary_electoral_district: true,
  corporation: true,
  total_childs: true,
  age_group: true,
  eligible_for_voting: true,
  unc_member_relation: true,
  swing_voter_label: true,
  importance_label: true,
  registered_for_election: true,
  gender: true,
  housing_status: true,
  ethinicity: true,
  religion_group: true,
  marital_status: true,
  pq2relation: true,
  profile_link: true,
  contact_url_company: true,
  profile_title: true,
  profile_summary: true,
  skills: true,
  languages: true,
  honors: true,
  certifications: true,
  publications: true,
  contact_url_portfolio: true,
  contact_url_blog: true,
  contact_url_rss: true,
  contact_url_other: true,
  experience_json: true,
  education_json: true,
};
export const AutoCompleteFields = {
  street_name: true,
  community: true,
  electoral_district: true,
  full_name: true,
  full_name_at_birth: true,
  fathers_given_names: true,
  mothers_given_names: true,
  fb_2015_profile_url: true,
};

export const RangeFields = {
  turn_out_score: true,
  party_support_score: true,
  registration_score: true,
};
