import React from "react";
import { getRoleFromToken } from "../../services/searchPageApis/api";

const LookerReport = () => {
  const role = getRoleFromToken();
  const iframeSrc =
    role === "Demo"
      ? "https://lookerstudio.google.com/embed/reporting/05b79e75-95f2-4ae5-ac2b-f2032e9c3a8c/page/kyhkD"
      : "https://lookerstudio.google.com/embed/reporting/f08a4e35-fd56-416c-87d0-cadcef45a4ee/page/kyhkD";
  return (
    <>
      <div className="container-fluid mt-3" style={{ height: "130vh" }}>
        <iframe
          width="100%"
          height="100%"
          src={iframeSrc}
          frameBorder="10"
          style={{
            border: "3px solid #ccc",
            borderRadius: "10px",
          }}
          allowFullScreen
          title="Dashboard"
        ></iframe>
      </div>
    </>
  );
};
export default LookerReport;
